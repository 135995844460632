import React from 'react'

const WarningV2 = () => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M59.25 32.5C59.25 47.2736 47.2736 59.25 32.5 59.25C17.7264 59.25 5.75 47.2736 5.75 32.5C5.75 17.7264 17.7264 5.75 32.5 5.75C47.2736 5.75 59.25 17.7264 59.25 32.5Z" stroke="#FFC400" strokeWidth="2.5"/>
    <path id="warningv2-path-1" d="M36 46.9155V42.5872C36.0048 42.3942 35.9334 42.2071 35.8013 42.0663C35.6692 41.9255 35.4871 41.8423 35.2942 41.8347H30.9192C30.7201 41.8362 30.5296 41.916 30.3888 42.0568C30.248 42.1976 30.1682 42.3881 30.1667 42.5872V46.9155C30.1682 47.1147 30.248 47.3052 30.3888 47.446C30.5296 47.5868 30.7201 47.6665 30.9192 47.668H35.2942C35.4871 47.6604 35.6692 47.5773 35.8013 47.4365C35.9334 47.2957 36.0048 47.1085 36 46.9155Z" fill="#FFC400" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
    <path id="warningv2-path-2" d="M35.6351 35.1739L35.9991 14.6895C36.0061 14.5821 35.9918 14.4743 35.9569 14.3723C35.9221 14.2704 35.8674 14.1764 35.7961 14.0957C35.7412 14.0171 35.6688 13.9524 35.5845 13.9067C35.5003 13.8609 35.4066 13.8354 35.3108 13.832H30.8541C30.7583 13.8354 30.6646 13.8609 30.5804 13.9067C30.4961 13.9524 30.4237 14.0171 30.3688 14.0957C30.2975 14.1764 30.2428 14.2704 30.208 14.3723C30.1731 14.4743 30.1588 14.5821 30.1658 14.6895L30.5158 35.1739C30.4973 35.3737 30.5587 35.5727 30.6866 35.7273C30.8146 35.8819 30.9985 35.9795 31.1983 35.9987H34.9491C35.1465 35.9733 35.327 35.8742 35.4543 35.7212C35.5816 35.5681 35.6462 35.3726 35.6351 35.1739Z" fill="#FFC400" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
  </svg>
)

export default WarningV2
