import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import i18next from 'i18next'

import Icon from 'lib/utils/icon'
import { Dialog } from '../Dialog'
import { goToPage } from '../../organisms/bpCommon'
import { setPdValue } from '../../organisms/ProfileDetails/pdSlice'
import { setBpValue } from 'lib/store/slices/batteryProfile'
import { setGCTBatModeDetails } from 'lib/services/batteryDetailsAPI'
import { DIALOG_TYPE, GCT_SURVEY_STATUS, GCT_SURVEY_ANS, GCT_SURVEY_QUESTIONAIRE, GCT_SURVEY_PAYLOAD } from 'lib/utils/constants'
import { isWeb } from 'lib/utils/utility'
import { popupFromIframe } from 'lib/common'
import './styles.scss'

function GCTSurvey(props) {

  const {showGCTSurvey} = props

  const initSurveyState = {
    questionId : GCT_SURVEY_QUESTIONAIRE.BATTERY_REBATE.ID,
    answerId: undefined,
    status: ''
  }
  const [surveyDetails, setSurveyDetails] = useState(initSurveyState)

  const dispatch = useDispatch()
  const setBpDetails = obj => dispatch(setBpValue({...obj}))
  const setPdSlice = obj => dispatch(setPdValue({...obj}))

  const setBatterySettings = async (payload) => {
    try {
      setSurveyDetails({...surveyDetails, status: GCT_SURVEY_STATUS.LOADING})
      await setGCTBatModeDetails(payload)
      setSurveyDetails({...surveyDetails, status: GCT_SURVEY_STATUS.SUCCESS})
    } catch (error) {
      setSurveyDetails({...initSurveyState, status: GCT_SURVEY_STATUS.ERROR})
    }
  }
  
  const handleConfirm = async () => {
    const { questionId, answerId, status } = surveyDetails
    // return if no survey option is selected
    if(status === '' && answerId === undefined) return
    
    if(status === '' && answerId && [GCT_SURVEY_QUESTIONAIRE.BATTERY_REBATE.ID, GCT_SURVEY_QUESTIONAIRE.HR_DYNAMIC_PRICING.ID].includes(questionId)) {
      if([GCT_SURVEY_ANS.YES.ID, GCT_SURVEY_ANS.NO.ID].includes(answerId)) {
        const payload = GCT_SURVEY_PAYLOAD[answerId]
        await setBatterySettings(payload)
      } else if(questionId === GCT_SURVEY_QUESTIONAIRE.BATTERY_REBATE.ID){
        setSurveyDetails({...surveyDetails, questionId: GCT_SURVEY_QUESTIONAIRE.HR_DYNAMIC_PRICING.ID, answerId : undefined })
      } else {
        setSurveyDetails({...initSurveyState, status: GCT_SURVEY_STATUS.WARNING })
      }
    } else {
      setSurveyDetails({...initSurveyState})
      if(status !== GCT_SURVEY_STATUS.ERROR) closeSurvey()
    }
  }

  const closeSurvey = () => {
    const { setShowGCTSurvey } = props
    const { status } = surveyDetails
    if(status !== GCT_SURVEY_STATUS.LOADING) {
      setSurveyDetails({...initSurveyState})
    }
    popupFromIframe({displayFooter: true})
    setShowGCTSurvey(false)
    if(status === GCT_SURVEY_STATUS.SUCCESS) {
      // redirect user to landing page
      setPdSlice({ fetchProfileDetails : true})
      goToPage({subPage: null, setBpDetails})
    }
  }

  const getDescriptionDetails = () => {
    const { status, answerId } = surveyDetails
    let details = { icon: '', title: '', description: ''}
    switch(status) {
      case GCT_SURVEY_STATUS.SUCCESS:
        details = { icon : 'success', title: i18next.t('bp510'), description: answerId === GCT_SURVEY_ANS.YES.ID ? i18next.t('bp511') : i18next.t('bp512')}
        break
      case GCT_SURVEY_STATUS.WARNING:
        details = { icon : 'warningV2', title: i18next.t('bp517'), description: i18next.t('bp513')}
        break
      case GCT_SURVEY_STATUS.ERROR:
        details = { icon : 'errorV2', title: i18next.t('bp514'), description: i18next.t('bp515')}
        break
      case GCT_SURVEY_STATUS.LOADING:
        details = { icon : 'loaderV4', title: null, description: answerId === GCT_SURVEY_ANS.YES.ID ? i18next.t('bp507') : i18next.t('bp508')}
        break
    }
    return details
  }
  
  const hanldeOptionChange = (option) => {
    setSurveyDetails({ ...surveyDetails, answerId : option})
  }

  const getSurveyDescription = () => {
    const { status, answerId } = surveyDetails
    if(status !== '') {
      const details = getDescriptionDetails()
      return ( 
        <div className='survey-container'>
          <div className="survey-status-wrapper">
            <div className='loader-container'>
              <Icon src={details.icon} />
            </div>
            {details.title && <div className='title'>{details.title}</div>}
            <div className='description'>{details.description}</div>
          </div>
          {status !== GCT_SURVEY_STATUS.LOADING && (
            <div className='survey-footer'>
              <button className='bp__action-button bp__ab--full-width' onClick={handleConfirm}>{status === GCT_SURVEY_STATUS.ERROR ? i18next.t('bp516') : i18next.t('bp509')}</button>
            </div>
          )}
        </div>
      )
    }
    return (
      <div className='survey-container'>
        <div className='survey-questionair-container'>
          <span className='survey-questionair'>{i18next.t(GCT_SURVEY_QUESTIONAIRE[surveyDetails.questionId]?.key)}</span>
          <div className='survey-option-container'>
            {Object.values(GCT_SURVEY_ANS).map((option, index) => {
              return (
                <div className='option-wrapper' key={`${option}-${index}`} onClick={() => hanldeOptionChange(option.ID)}  >
                  <div className='bp__radio-checkbox__button'>
                    <input type="radio" className='aurvey-radio-option' name="option-name" readOnly checked={surveyDetails.answerId === option.ID} />
                    <span className='survey-radio-label'>{i18next.t(option.key)}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='survey-footer mt-12'>
          <button className={`bp__action-button bp__ab--full-width ${answerId === undefined && 'disable-survey-btn'}`} onClick={handleConfirm}>{i18next.t('bp368')}</button>
        </div>
      </div>
    )
  }

  /* *********************** *********************** *********************** *
   * Return View
   * *********************** *********************** *********************** */
  
  if(!showGCTSurvey) return null

  return (
    <>
      <Dialog
        className={`e_dialog__profile sp__gct-survey__info-popup`}
        type={isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM}
        showCloseIcon={true}
        showDialog={showGCTSurvey}
        title={i18next.t('bp501')}
        content={getSurveyDescription()}
        onClickCloseIcon={closeSurvey}
      />
    </>
  )
}

export default GCTSurvey
