/* eslint-disable fp/no-mutating-methods */
/* eslint-disable fp/no-mutation */
import i18next from 'i18next'
import _ from 'lodash'
import {handleCloseWeb, handleError} from 'lib/common'
import {HTTPS_API_STATUS, HTTPS_REQUEST_TYPE} from 'lib/services/httpRequest'
import {cancelProfileRequest, getProfileDetails, setProfileDetails} from 'lib/services/profileDetailsAPI'
import {
  DIALOG_TYPE,
  DISCLAIMER_TYPE,
  HEMS_ACTIVATION_STATUS,
  OMST_STATUS,
  PAGE_NAME,
  PROFILE_STATUS,
  PROFILE_TYPE,
  TOAST_TYPE,
} from 'lib/utils/constants'
import {
  hasEncharge,
  systemWithEnchargeAndGridAgnostic,
  hasSystemCard,
  isSettingsLocked,
  isDTEnabledSite,
  isDTSupportedSite,
  isNem3Supported,
  isNem3TariffSupported,
  getAcceptedGICDisclaimer,
  isNEM3Site,
  isHemsActivationPending,
  isEvChargingModesSupported,
} from 'lib/utils/siteDetails'
import {isUserHasAccess} from 'lib/utils/userDetails'
import {omit} from 'lib/utils/utility'
import {getBatteryProfile} from 'lib/store'
import {setDisclaimer} from 'lib/services/disclaimerAPI'
import {getHemsSiteActivationStatus} from 'lib/services/siteSettingsAPI'
import {showToast} from '../atoms/Toast'
import {getBatteryReserveValue, hasBatteryReserveValueChanged} from '../molecules/BatteryReserve/brCore'
import {
  getDeviceListForProfileChange,
  getUpdatedDeviceListForChargingMode,
  hasDeviceBehaviourChanged,
} from '../molecules/DeviceBehaviour/dbCore'
import {checkDtTariffs, checkILNEMTariff, checkTouAndBuybackExportPlan, getTouAlertDetails} from '../molecules/SavingsProfile/spCore'
import {
  getAfterPeakToggleStatus,
  getAfterPeakToggleValue,
  isCurrentRequestHasAfterPeakChanges,
} from '../molecules/EditAfterPeakHours/eaphCore'

import {getStormAlertDetails, isStormAlertActive} from './StormGuardDetails/sgCore'
import {
  getActiveRequestAlertDetails,
  showActiveRequestDialog,
  updateProfileSliceValues,
} from './ProfileDetails/pdCore'
import {getRequestType} from './mqttRequest'
import {isRequestPendingForProfile} from './reqPending'
import {
  checkDeviceProfileUpdating,
  checkDeviceProfileUpdatingDialog,
  getDevices,
} from '../atoms/EvTopUpSchedule/evScheduleCore'
import {getGicDisclaimerDetails} from '../molecules/GicDisclaimer'
import LockPopUp from '../molecules/LockPopUp'

// Go to Page
export function goToPage(obj) {
  const {subPage, setBpDetails} = obj
  const subPageList = []

  if (!_.isNil(subPage) && subPage?.length > 0) {
    if (_.isArray(subPage)) {
      subPageList.push(...subPage)
    } else {
      subPageList.push(subPage)
    }
  }

  setBpDetails({subPage: subPageList})
}

// Verify for active page
export function isActivePage(page) {
  let [subPage] = getBatteryProfile().subPage

  if (!_.isArray(subPage)) {
    subPage = [subPage]
  }
  const currentPage = _.first(subPage)
  return currentPage === page
}

// Show Toast or Dialog and restrict user to perform action
export function restrictUserToPerformAction({
  props,
  details,
  inProgress,
  showUserAccessToast,
  checkUserAccess,
  showDialog,
  closeThisDialog,
  renderFindMyProfileTutorial,
  enableRetry,
}) {
  const closeDialog = props?.fromTutorial ? renderFindMyProfileTutorial : closeThisDialog

  // Check User Access
  if (!(checkUserAccess === false) && !isUserHasAccess(!_.isNil(showUserAccessToast) ? showUserAccessToast : true)) {
    return _.isBoolean(checkUserAccess) ? checkUserAccess : true
  }

  if (isSettingsLocked()) {
    showDialog && showDialog(LockPopUp(closeThisDialog))
    return true
  }

  if (!enableRetry && isStormAlertActive(details)) {
    showDialog && showDialog(getStormAlertDetails({details, closeThisDialog}))
    return true
  }

  if (checkDeviceProfileUpdating(details)) {
    showDialog && showDialog(checkDeviceProfileUpdatingDialog({details, closeThisDialog: closeDialog}))
    return true
  }

  if (showActiveRequestDialog({profile: PROFILE_TYPE.COST_SAVINGS, inProgress, details})) {
    showDialog && showDialog(getActiveRequestAlertDetails({details, closeThisDialog: closeDialog}))
    return true
  }

  return false
}

// Handle hems site activation process
async function handleHemsSiteActivation(args, counter = 0) {
  const {setPdSlice, showLoader, setSiteSettingsValue} = args

  // Handle Hems site activation failed response
  const handleHemsSiteActivationFailed = () => {
    setPdSlice({loading: false})
    showLoader({show: false})
    showToast({
      type: TOAST_TYPE.ERROR,
      message: i18next.t('bp315'),
      autoClose: 3000,
    })
  }
  const apiCallCounter = counter + 1

  try {
    const resActivationStatus = await getHemsSiteActivationStatus()
    if (resActivationStatus?.status === HTTPS_API_STATUS.SUCCESS) {
      if (
        resActivationStatus?.data &&
        HEMS_ACTIVATION_STATUS in resActivationStatus.data &&
        resActivationStatus.data[HEMS_ACTIVATION_STATUS].toLowerCase() === PROFILE_STATUS.ACTIVE
      ) {
        setSiteSettingsValue({isHemsActivationPending: false, isHemsActivatedFromUI: true}) // Update state
        // Continue profile switching flow
        const updatedArgs = {...args, skipHemsSiteActivation: true}
        applySystemProfileRequest(updatedArgs)
      } else if (apiCallCounter > 2) {
        handleHemsSiteActivationFailed()
      } else {
        handleHemsSiteActivation(args, apiCallCounter)
      }
    } else {
      handleHemsSiteActivationFailed()
    }
  } catch (error) {
    console.error('[Error] handleHemsSiteActivation', error)
    handleHemsSiteActivationFailed()
  }
}

// Show GIC Disclaimer Dialog
async function showGicDisclaimer(args) {
  const {showDialog, closeThisDialog, showLoader, setPdSlice, setSiteSettingsValue} = args

  // GIC Dialog :: On Close Disclaimer
  const closeGicDisclaimer = () => {
    setPdSlice({loading: false, inProgress: null})
    closeThisDialog()
  }

  // GIC Dialog :: On Confirm Disclaimer
  const confirmGicDisclaimer = async data => {
    showLoader({show: true})
    closeThisDialog()
    if (data) {
      try {
        const resProfile = await setDisclaimer({[DISCLAIMER_TYPE.KEY]: DISCLAIMER_TYPE.GIC}) // Enabled GIC Disclaimer
        if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
          setSiteSettingsValue({acceptedGICDisclaimer: true}) // Update state
          // Continue profile switching flow
          const updatedArgs = {...args, skipGicDisclaimer: true}
          applySystemProfileRequest(updatedArgs)
        } else {
          throw new Error('Error')
        }
      } catch (error) {
        console.error('[Error] confirmGicDisclaimer', error)

        setPdSlice({loading: false, inProgress: null})
        showLoader({show: false})
        showToast({
          type: TOAST_TYPE.ERROR,
          message: i18next.t('bp68'),
          autoClose: 3000,
        })
      }
    } else {
      // Continue profile switching flow
      const updatedArgs = {...args, skipGicDisclaimer: true}
      applySystemProfileRequest(updatedArgs)
    }
  }

  const gicDisclaimerObj = getGicDisclaimerDetails({
    closeGicDisclaimer,
    confirmGicDisclaimer,
    redirectFrom: PAGE_NAME.PROFILE,
  })
  showLoader({show: false})
  showDialog(gicDisclaimerObj)
}

// Apply Current Profile Request
export async function applySystemProfileRequest(args) {
  const {
    profile,
    props,
    details,
    profileDetails,
    dispatch,
    showDialog,
    closeThisDialog,
    showLoader,
    setBpDetails,
    setBatteryData,
    setPdSlice,
    setDeviceBehaviorDisable,
    deviceChanges,
    skipGicDisclaimer,
    skipHemsSiteActivation,
  } = args

  // Display GIC disclaimer details
  if (
    details?.profile !== PROFILE_TYPE.COST_SAVINGS &&
    profile === PROFILE_TYPE.COST_SAVINGS &&
    isDTSupportedSite() &&
    hasEncharge() &&
    isDTEnabledSite() &&
    !getAcceptedGICDisclaimer() &&
    !skipGicDisclaimer
  ) {
    showGicDisclaimer(args)
    return
  }

  // Display TOU alert details
  if (profile === PROFILE_TYPE.COST_SAVINGS && (!checkDtTariffs(details) || !checkILNEMTariff() || checkTouAndBuybackExportPlan(details))) {
    const obj = getTouAlertDetails({details, showLoader, setPdSlice, closeThisDialog, showToast})
    showDialog(obj)
    showLoader({show: false})
    return
  }

  if (
    profile === PROFILE_TYPE.COST_SAVINGS &&
    isCurrentRequestHasAfterPeakChanges(details, profileDetails.operationModeSubType)
  ) {
    setPdSlice({isAfterPeakLoading: true})
  }

  // Hems site activation flow
  if (
    profile === PROFILE_TYPE.COST_SAVINGS &&
    isHemsActivationPending() &&
    !skipHemsSiteActivation &&
    (isDTSupportedSite() || isNEM3Site())
  ) {
    handleHemsSiteActivation(args)
    return
  }

  // profile
  const obj = {
    profile,
  }

  // operationModeSubType
  if (profile === PROFILE_TYPE.COST_SAVINGS) {
    obj.operationModeSubType = getAfterPeakToggleValue(profileDetails.operationModeSubType)
  }

  // batteryBackupPercentage
  if (systemWithEnchargeAndGridAgnostic()) {
    obj.batteryBackupPercentage = getBatteryReserveValue({profile, details})

    if (props?.fromTutorial && props.batteryBackupPercentage) {
      obj.batteryBackupPercentage = props.batteryBackupPercentage
    }
  }

  // devices
  if (hasSystemCard()) {
    const devices = getDevices(details)
    const deviceChangeObj = getDeviceListForProfileChange(details?.profile, devices, deviceChanges, profile, dispatch)
    if (deviceChangeObj?.length > 0) {
      obj.devices = isEvChargingModesSupported()
        ? getUpdatedDeviceListForChargingMode(deviceChangeObj, profile)
        : deviceChangeObj
      setDeviceBehaviorDisable({deviceChangeDisable: true})
    }
  }

  // prioritize energy
  if (isNem3Supported() && isNem3TariffSupported() && obj?.profile && obj.profile === PROFILE_TYPE.COST_SAVINGS) {
    obj.operationModeSubType = OMST_STATUS.PRIORITIZE_ENERGY
  }

  // flags
  const requestType = getRequestType(HTTPS_REQUEST_TYPE.MQTT, {details})
    ? HTTPS_REQUEST_TYPE.MQTT
    : HTTPS_REQUEST_TYPE.API
  setPdSlice({requestType, inProgress: profile, loading: true})

  // Set Profile Details
  try {
    const resProfile = await setProfileDetails(obj)
    if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
      if (resProfile.message !== 'success') {
        showToast({
          type: TOAST_TYPE.INFO,
          message: resProfile.message,
          autoClose: 3000,
        })
        showLoader({show: false})
      } else {
        showLoader({show: false})
        goToPage({subPage: null, setBpDetails})

        if (getRequestType(HTTPS_REQUEST_TYPE.MQTT, {details})) {
          setBatteryData({requestedConfigMqtt: {...details.requestedConfigMqtt, ...obj}})
        } else if (getRequestType(HTTPS_REQUEST_TYPE.API, {details})) {
          setBatteryData({requestedConfig: {...details.requestedConfig, ...obj}})
          setPdSlice({loading: false})
        }
      }
    } else {
      throw new Error('Error')
    }
  } catch (error) {
    console.error('[Error] applySystemProfileRequest', error)

    if (profile === PROFILE_TYPE.COST_SAVINGS) {
      setPdSlice({isAfterPeakLoading: false})
    }

    setPdSlice({requestType: null, inProgress: null, loading: false})
    setDeviceBehaviorDisable({deviceChangeDisable: false})

    showToast({
      type: TOAST_TYPE.ERROR,
      message: i18next.t('bp68'),
      autoClose: 3000,
    })
    showLoader({show: false})
  }
}

// Cancel Active Profile Request
export async function cancelSystemProfileRequest(args) {
  const {ref, profile, details, dispatch, showLoader, setBatteryData, setPdSlice, setDeviceBehaviorDisable} = args

  if (details.systemTask) {
    return
  }

  const obj = {}

  obj.requestedConfig = omit(details.requestedConfig, [
    'profile',
    'batteryBackupPercentage',
    'operationModeSubType',
    'systemTask',
  ])

  setPdSlice({requestType: HTTPS_REQUEST_TYPE.API, inProgress: profile, loading: true})

  // Cancel Profile Request
  try {
    const resProfile = await cancelProfileRequest()
    if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
      showToast({
        type: TOAST_TYPE.SUCCESS,
        message: i18next.t('bp75'),
        autoClose: 3000,
      })

      setPdSlice({operationModeSubType: getAfterPeakToggleStatus({...details, ...obj})})
      setBatteryData(obj)
      setPdSlice({loading: false, openCard: details.profile})

      if (hasSystemCard()) {
        setDeviceBehaviorDisable({deviceChangeDisable: false})
      }

      showLoader({show: false})
    } else {
      throw new Error('Error')
    }
  } catch (e) {
    const err = e.error || e

    setPdSlice({loading: false})
    handleError({error: e, showToast, dispatch})

    if (err.response.status === 409) {
      const resProfile = await getProfileDetails()
      updateProfileSliceValues({source: HTTPS_REQUEST_TYPE.API, dispatch, data: resProfile.data})
      showLoader({show: false})
      ref.style.display = 'block'
    } else {
      showLoader({show: false})
    }
  } finally {
    if (profile === PROFILE_TYPE.COST_SAVINGS) {
      setPdSlice({isAfterPeakLoading: false})
    }

    setPdSlice({requestType: null, inProgress: null})
  }
}

// Discard Popup Content
export function getDiscardPopupDetails(
  setBpDetails,
  closeThisDialog,
  deviceChanges,
  devices,
  removeDeviceUpdate,
  openCard,
  setPdSlice,
  reqProfile
) {
  const discardPopupDescription = i18next.t('bp232')
  const obj = {
    className: 'bp__discard__change-popup',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: i18next.t('bp233'),
    content: discardPopupDescription,
    onClickCloseIcon: closeThisDialog,
  }

  const onClickDiscard = () => {
    if (deviceChanges.length > 0 && hasDeviceBehaviourChanged(deviceChanges, devices)) {
      devices?.forEach(device => {
        removeDeviceUpdate({profile: openCard, uuid: device.uuid})
      })
    }
    if (reqProfile) {
      setPdSlice({loading: false, openCard: reqProfile})
    }
    goToPage({subPage: null, setBpDetails})
    closeThisDialog()
  }

  obj.buttons = [
    {
      value: i18next.t('bp105'),
      action: closeThisDialog,
      disabled: false,
      className: 'bp__ab--mobile-view',
    },
    {
      value: i18next.t('bp104'),
      action: () => onClickDiscard(),
      className: 'bp__ab--mobile-view',
    },
  ]

  return obj
}

// Check if info Changed in profile page
export function isInfoChanged(profileDetails, deviceChanges, details, devices, spDetails) {
  const selectedProfile = profileDetails.openCard

  if (details.profile !== selectedProfile) {
    return true
  }

  if (deviceChanges.length > 0) {
    return hasDeviceBehaviourChanged(deviceChanges, devices)
  }

  if (!_.isNull(profileDetails.inProgress)) {
    return profileDetails.inProgress === selectedProfile
  }

  if (hasBatteryReserveValueChanged(details, selectedProfile)) {
    return true
  }

  if (isCurrentRequestHasAfterPeakChanges(details, profileDetails.operationModeSubType)) {
    return true
  }

  return (
    !isRequestPendingForProfile(details) && details.profile && details.profile !== selectedProfile && !spDetails.type
  )
}

export const closeWebView = () => {
  handleCloseWeb()
}
