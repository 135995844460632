/* eslint-disable fp/no-mutation */

import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import i18next from 'i18next'
import {handleError} from 'lib/common'
import {cancelBatteryRequest, setBatteryDetails} from 'lib/services/batteryDetailsAPI'
import {HTTPS_API_STATUS, HTTPS_REQUEST_TYPE} from 'lib/services/httpRequest'
import {batteryProfileSelector, setBpDetailsValue} from 'lib/store/slices/batteryProfile'
import {ACTION_TYPE, PENDING_REQ_KEY, PROFILE_STATUS, TOAST_TYPE} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {showVLS, isIQGWScheduleSupportedSite, isSettingsLocked} from 'lib/utils/siteDetails'
import {isUserHasAccess} from 'lib/utils/userDetails'
import {omit} from 'lib/utils/utility'
import Analytics from 'lib/utils/analytics'
import {IntractiveButton} from '../../atoms/IntractiveButton'
import {RangeSlider} from '../../atoms/RangeSlider'
import {showToast} from '../../atoms/Toast'
import {init, updateBatterySliceValues} from '../../organisms/BatteryDetails/bdCore'
import {getRequestType} from '../../organisms/mqttRequest'
import {
  isAnyRequestPendingForBatteryReserveOrShutdown,
  isRequestPendingForProfile,
  isVeryLowSocRequestPending,
} from '../../organisms/reqPending'
import {getBatteryReserveValue} from '../BatteryReserve/brCore'
import {setBrValue} from '../BatteryReserve/brSlice'
import {
  getBatteryShutdownDescription,
  getBatteryShutdownSubDescription,
  getCardDetails,
  getRangeErrorText,
  restrictUserToPerformBsAction,
} from './bsCore'
import {setBsValue} from './bsSlice'
import LockPopUp from '../LockPopUp'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import './styles.scss'

/* *********************** *********************** *********************** *
 *  Battery Shutdown
 * *********************** *********************** *********************** */

export const BatteryShutdown = () => {
  const batteryProfile = useSelector(batteryProfileSelector)
  const {batteryReserve} = useSelector(state => state.components)
  const {loading, veryLowSoc, rangeErrorText, isBsInfoChanged, isToastOpened} = useSelector(
    state => state.components.batteryShutdown
  )
  const isCbfgLoading = useSelector(state => state.components.chargeBatteryFromGrid.loading)
  const isDbtgLoading = useSelector(state => state.components.dischargeBatteryToGrid.loading)
  const isBatteryRequestAnyRunning = isCbfgLoading || isDbtgLoading

  const isCbfgInfoChanged = useSelector(state => state.components.chargeBatteryFromGrid.isCbfgInfoChanged)
  const isDbtgInfoChanged = useSelector(state => state.components.dischargeBatteryToGrid.isDbtgInfoChanged)

  const {details} = batteryProfile

  const bsTitle = i18next.t('bp47')
  const bsValueText = () => (_.isNumber(veryLowSoc) ? `${veryLowSoc}%` : veryLowSoc)
  const bsDescription = getBatteryShutdownDescription({value: bsValueText()})
  const bsSubDescription = getBatteryShutdownSubDescription()
  const bsDetails = getCardDetails(details)
  const bsButtonLabel = bsDetails.buttonLabel
  const bsButtonActionType = bsDetails.buttonActionType

  const dispatch = useDispatch()
  const setBsSlice = obj => dispatch(setBsValue(obj))
  const setLoading = val => dispatch(setBsValue({loading: val}))
  const setRequestType = val => dispatch(setBsValue({requestType: val}))
  const setBsInfoChanged = val => dispatch(setBsValue({isBsInfoChanged: val}))
  const setIsToastOpened = val => dispatch(setBsValue({isToastOpened: val}))
  const resetBsData = obj => dispatch(setBsValue({...obj}))

  const setBatteryData = obj => dispatch(setBpDetailsValue(obj))
  const setBrSlice = obj => dispatch(setBrValue(obj))
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const closeThisDialog = () => dispatch(closeDialog())
  // useEffect on mount
  useEffect(() => {
    updateBatterySliceValues({source: 'shutdown', dispatch, data: details})
    setLoading(isVeryLowSocRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT))
    setBsSlice({rangeErrorText: null})
  }, [batteryProfile.loading])

  // Reset Battery shutdown slice if user updating CFG/DTG card
  useEffect(() => {
    if (isIQGWScheduleSupportedSite() && (isCbfgInfoChanged || isDbtgInfoChanged)) {
      resetBsData({
        isBsInfoChanged: false,
        veryLowSoc: details?.veryLowSoc || null,
      })
    }
  }, [isCbfgInfoChanged, isDbtgInfoChanged])

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  // RangeSlider :: disable
  function disableRangeSlider() {
    return (
      loading ||
      batteryReserve.loading ||
      isAnyRequestPendingForBatteryReserveOrShutdown(details) ||
      isRequestPendingForProfile(details)
    )
  }

  // onChange Range Slider
  const onChangeRangeSlider = (value, index) => {
    if (
      restrictUserToPerformBsAction({
        details,
        isBatteryRequestAnyRunning,
        showToastMsg: !isToastOpened,
        setIsToastOpened,
      })
    ) {
      return
    }
    if(isSettingsLocked()){
      showDialog(LockPopUp(closeThisDialog))
      return
    }

    const {rangeMax} = bsSlider
    const rangeMin = details?.veryLowSocMin
    const val = _.isArray(value) ? value[index] : value

    if (!disableRangeSlider()) {
      setBsSlice({
        rangeErrorText: getRangeErrorText(details, val, details?.batteryBackupPercentage, rangeMin, rangeMax),
      })
      setBsSlice({veryLowSoc: val})
      setBrSlice({brReserve: details.batteryBackupPercentage})
    }

    // updateBatterySliceValues({source: 'reserve', dispatch, profile, data: {...details, ...{veryLowSoc: val}}})
  }

  const onAfterChangeRangeSlider = value => {
    Analytics.sendClickEvent(`Battery_Shutdown_Level_${value && value[1]}`, 'Battery_Settings')
  }

  // RangeSlider :: onClick
  const onClickRangeSlider = () => {
    if (
      restrictUserToPerformBsAction({
        details,
        isBatteryRequestAnyRunning,
        showToastMsg: !isToastOpened,
        setIsToastOpened,
      })
    ) {
      return
    }
    if(isSettingsLocked()){
      showDialog(LockPopUp(closeThisDialog))
      return
    }

    if (disableRangeSlider()) {
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp77'),
      })
    }
  }

  // onClick Action Button
  const onClickActionButton = obj => {
    Analytics.sendClickEvent('Save_Battery_Shutdown_Level', 'Battery_Settings')
    const {type} = obj

    // Check User Access
    if (!isUserHasAccess()) {
      return
    }

    if (type === ACTION_TYPE.SAVE) {
      save()
    } else if (obj.type === ACTION_TYPE.CANCEL) {
      cancel()
    }
  }

  /* *********************** *********************** *********************** *
   * Functions
   * *********************** *********************** *********************** */

  // isInfoChanged
  function isInfoChanged() {
    if (loading || bsDetails.type === PROFILE_STATUS.PENDING) {
      return true
    }

    if (
      _.isNil(details?.veryLowSoc) ||
      _.isNil(veryLowSoc) ||
      veryLowSoc > getBatteryReserveValue({profile: null, details}) ||
      isAnyRequestPendingForBatteryReserveOrShutdown(details)
    ) {
      return false
    }

    if (isIQGWScheduleSupportedSite() && details?.veryLowSoc !== veryLowSoc && !isBsInfoChanged) setBsInfoChanged(true)
    return details?.veryLowSoc !== veryLowSoc
  }

  // Save
  async function save() {
    const obj = {
      veryLowSoc: bsValue,
    }

    setRequestType(
      getRequestType(HTTPS_REQUEST_TYPE.MQTT, {details}) ? HTTPS_REQUEST_TYPE.MQTT : HTTPS_REQUEST_TYPE.API
    )
    setLoading(true)

    // Set Battery Details
    try {
      const resProfile = await setBatteryDetails(obj)
      if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
        if (getRequestType(HTTPS_REQUEST_TYPE.MQTT, {details})) {
          setBatteryData({requestedConfigMqtt: {...details.requestedConfigMqtt, ...obj}})
        } else if (getRequestType(HTTPS_REQUEST_TYPE.API, {details})) {
          setBatteryData({requestedConfig: {...details.requestedConfig, ...obj}})
        }
      } else {
        throw new Error('Error')
      }
    } catch (error) {
      console.error('[Error] Battery Shutdown - Save', error)

      setRequestType(null)
      setLoading(false)

      showToast({
        type: TOAST_TYPE.ERROR,
        message: i18next.t('bp68'),
        autoClose: 3000,
      })
    }
  }

  // Cancel
  async function cancel() {
    const obj = {}
    obj.requestedConfig = omit(details.requestedConfig, ['veryLowSoc'])

    setRequestType(HTTPS_REQUEST_TYPE.API)
    setLoading(true)

    // Cancel Battery Shutdown Request
    try {
      const resProfile = await cancelBatteryRequest({type: 'veryLowSoc'})
      if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
        showToast({
          type: TOAST_TYPE.SUCCESS,
          message: i18next.t('bp76'),
          autoClose: 3000,
        })
        setBatteryData(obj)
        updateBatterySliceValues({source: 'shutdown', dispatch, data: {...details, ...obj}})
        setLoading(false)
      } else {
        throw new Error('Error')
      }
    } catch (e) {
      const err = e.error || e

      setLoading(false)
      handleError({error: e, showToast, dispatch})

      if (err.response.status === 409) {
        init({dispatch})
      }
    } finally {
      setRequestType(null)
    }
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  // Hide Battery Shutdown
  if (!showVLS()) {
    return false
  }

  // Range Slider
  const bsMin = 0
  const bsMax = details?.veryLowSocMax || 25
  const rangeMin = details?.veryLowSocMin || 5
  const bsValue = veryLowSoc

  const marks = _.range(bsMin, bsMax + 1, 5)
  const ticks = marks.reduce((a, v) => ({...a, [v]: `${v}%`}), {})
  const bsSlider = {
    className: '',
    isDisabled: disableRangeSlider(),
    value: [rangeMin, bsValue],
    min: bsMin,
    max: bsMax,
    rangeMin,
    rangeText: rangeErrorText,
    marks,
    ticks,
    showTrackValue: false,
    onChange: onChangeRangeSlider,
    onClick: onClickRangeSlider,
    onAfterChange: onAfterChangeRangeSlider,
    isLocked: isSettingsLocked()
  }

  return (
    // BatteryShutdown
    <section id="bp-battery-shutdown" className="bp__card-view">
      <div className="bp__card-view__wrapper">
        <div className="bs__title-wrapper">
          <div className="bp__card-view--title">{bsTitle}</div>
          <div className="bs__value">{bsValueText()}</div>
        </div>

        <div className="bs__slider-wrapper">
          <div className="bs__slider-icon">
            <Icon src="batteryShutDown" />
          </div>
          <div className="bs__slider">
            <RangeSlider {...bsSlider} />
          </div>
        </div>

        <div className="bp__card-view--desc">{bsDescription}</div>
        <div className="bp__card-view--sub-desc">{bsSubDescription}</div>
      </div>

      {isInfoChanged() && (
        <IntractiveButton
          className="ib__btn"
          type={bsButtonActionType}
          label={bsButtonLabel}
          loading={loading}
          disabled={!isInfoChanged()}
          onClick={onClickActionButton}
        />
      )}
    </section>
  )
}

BatteryShutdown.propTypes = {}

BatteryShutdown.defaultProps = {}
