/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import Icon from 'lib/utils/icon'
import _ from 'lodash'
import {getBatteryProfileDetails} from 'lib/store'
import {getTimeError} from 'lib/utils/utility'
import {navigateToElectricityRate, popupFromIframe} from 'lib/common'
import {addMinuteToDate, minutesToUTCDate, stringToMinutes} from 'lib/utils/timeDetails'
import {
  IQGWScheduleSupportWithSavingsMode,
  chargeFromGridScheduleEnabled,
  isSettingsLocked,
  isIQGWScheduleSupportedSite,
  isNEM3ExpOnlySite,
  isSiteGridAgnostic,
  showChargeFromGrid,
  getGridConnectionType,
} from 'lib/utils/siteDetails'
import {
  ACTION_TYPE,
  COMPONENT_SHORT_HAND,
  DIALOG_TYPE,
  PENDING_REQ_KEY,
  PROFILE_STATUS,
  PROFILE_TYPE,
  TOAST_TYPE,
  TOGGLE_SWITCH_STATUS,
} from 'lib/utils/constants'
import {
  isAnyRequestPendingForChargeBatteryFromGrid,
  isAnyRequestPendingForDischargeBatteryToGrid,
  isDischargeStartTimeRequestPending,
  isDischargeEndTimeRequestPending,
  isProfileRequestPending,
  isRequestPendingForDischargeBatteryToGridByRequestType,
  isDischargeToGridRequestPending,
  isAnyRequestPendingForProfile,
  isDischargeScheduleSupportedRequestPending,
  isDischargeLockedRequestPending,
  isAnyRequestPendingForBatteryShutdown,
} from '../../organisms/reqPending'
import {showToast} from '../../atoms/Toast'
import LockPopUp from '../LockPopUp'

// Discharge Battery to Grid :: Card Details
export function getCardDetails(details) {
  const isAnyReqPending = isAnyRequestPendingForDischargeBatteryToGrid(details)
  const isMqttReqPending = isRequestPendingForDischargeBatteryToGridByRequestType(
    details,
    PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT
  )
  const isReqPending = isRequestPendingForDischargeBatteryToGridByRequestType(details, PENDING_REQ_KEY.REQUESTED_CONFIG)

  if (details && !isAnyReqPending) {
    return {
      type: PROFILE_STATUS.ACTIVE,
      typeText: 'Active',
      buttonLabel: i18next.t('bp210'),
      buttonActionType: ACTION_TYPE.SAVE,
    }
  }

  if (isMqttReqPending) {
    return {
      type: PROFILE_STATUS.PROCESSING,
      typeText: null,
      buttonLabel: i18next.t('bp48'),
      buttonActionType: ACTION_TYPE.SAVE,
    }
  }

  if (isReqPending) {
    return {
      type: PROFILE_STATUS.PENDING,
      typeText: i18next.t('bp100'),
      buttonLabel: i18next.t('bp49'),
      buttonActionType: ACTION_TYPE.CANCEL,
    }
  }

  return {
    buttonLabel: i18next.t('bp48'),
    buttonActionType: ACTION_TYPE.SAVE,
  }
}

export function getSystemProfile(details) {
  if (isAnyRequestPendingForProfile(details)) {
    return details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT]?.profile ?? details[PENDING_REQ_KEY.REQUESTED_CONFIG]?.profile
  }
  return details.profile
}

const getDbtgDescForSelfConsProfile = details => {
  let desc = i18next.t('bp401')
  if (isNEM3SelfConsExpOnlySite(details)) {
    desc = i18next.t('bp466')
  } else if (isSiteGridAgnostic()) desc = i18next.t('bp403')
  return desc
}

// Discharge Battery to Grid :: Get Description
export function getDescription(status, details) {
  const profile = getSystemProfile(details)

  let text = ''
  // when toggle status is OFF
  if (status === TOGGLE_SWITCH_STATUS.OFF) {
    text = i18next.t('bp400')
    if (profile === PROFILE_TYPE.BACKUP_ONLY) {
      text = i18next.t('bp405')
    } else if (isNEM3SelfConsExpOnlySite(details)) {
      text = i18next.t('bp461')
    }
    return text
  }

  // when toggle status is ON
  switch (profile) {
    case PROFILE_TYPE.COST_SAVINGS:
      text = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp440') : i18next.t('bp412')
      break
    case PROFILE_TYPE.BACKUP_ONLY:
      text = i18next.t('bp405')
      break
    case PROFILE_TYPE.SELF_CONSUMPTION:
      text = getDbtgDescForSelfConsProfile(details)
      break
    default:
      break
  }
  return text
}

// Discharge Battery to Grid :: Get Info Description
export function getInfoDescription(details) {
  let descEnabled = isSiteGridAgnostic() ? i18next.t('bp403') : i18next.t('bp401')
  let descDisabled = i18next.t('bp400')

  if (isNEM3SelfConsExpOnlySite(details)) {
    descEnabled = i18next.t('bp462')
    descDisabled = i18next.t('bp461')
  }

  return (
    <div className="dbtg__popup__wrapper">
      <div className="e_dialog__content">
        <span className="bp__status-text--enabled">{i18next.t('bp7')}</span>: {descEnabled}
      </div>
      <div className="e_dialog__content">
        <span className="bp__status-text--disabled">{i18next.t('bp8')}</span>: {descDisabled}
      </div>
    </div>
  )
}

// Discharge Battery to Grid :: Get Toggle Status
export function getDbtgToggleStatus(details, loading) {
  const onSubType = getDischargeBatteryToGrid(details)

  if (loading) return TOGGLE_SWITCH_STATUS.LOADING
  if (onSubType) return TOGGLE_SWITCH_STATUS.ON
  return TOGGLE_SWITCH_STATUS.OFF
}

// Discharge Battery to Grid :: Get requested discharge to grid status
export function getDischargeBatteryToGrid(details) {
  if (isDischargeToGridRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].dtgControl?.enabled
  }

  if (isDischargeToGridRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].dtgControl?.enabled
  }

  return details?.dtgControl?.enabled || false
}

export function isCFGAndDTGScheduleOverlapping(source, cbfgSchedule, dbtgSchedule) {
  let dbStartTime
  let dbEndTime
  let currentStartTime
  let currentEndTime
  let hasMultiDateSchedule = false

  if (source === COMPONENT_SHORT_HAND.CHARGE_BATTERY_FROM_GRID) {
    // dbStartTime and dbEndTime take from DTG schedule
    dbStartTime = dbtgSchedule?.startTime
    dbEndTime = dbtgSchedule?.endTime

    currentStartTime = cbfgSchedule?.startTime
    currentEndTime = cbfgSchedule?.endTime
  } else {
    // dbStartTime and dbEndTime take from CFG schedule
    dbStartTime = cbfgSchedule?.startTime
    dbEndTime = cbfgSchedule?.endTime

    currentStartTime = dbtgSchedule?.startTime
    currentEndTime = dbtgSchedule?.endTime
  }

  // convert startTime and endTime in UTC date time format
  currentEndTime =
    currentEndTime < currentStartTime ? 1440 - currentStartTime + currentEndTime : currentEndTime - currentStartTime
  if (dbEndTime < dbStartTime) {
    hasMultiDateSchedule = true
    dbEndTime = 1440 - dbStartTime + dbEndTime
  } else {
    dbEndTime -= dbStartTime
  }

  dbStartTime = minutesToUTCDate(dbStartTime)
  dbEndTime = addMinuteToDate(dbStartTime, dbEndTime)

  currentStartTime = minutesToUTCDate(currentStartTime)
  currentEndTime = addMinuteToDate(currentStartTime, currentEndTime)

  // Validate if selected schedule duration is not overlapping with db schedule
  if (hasMultiDateSchedule) {
    // checks if selected schedule not overlap within two day db schedule
    if (
      currentStartTime.isBefore(dbStartTime) &&
      currentStartTime.add('1', 'day').isAfter(dbEndTime) &&
      currentEndTime.isBefore(dbStartTime) &&
      currentEndTime.add('1', 'day').isAfter(dbEndTime)
    ) {
      return false
    }
  } else if (
    // check if selected schedule not overlap within a day db schedule
    (currentStartTime.isBefore(dbStartTime) && currentEndTime.isBefore(dbStartTime)) ||
    (currentStartTime.isAfter(dbEndTime) && currentEndTime.isBefore(dbStartTime.add('1', 'day')))
  ) {
    return false
  }

  return true
}

export function isCFGAndDTGScheduleDurationConflict(source, cbfgSchedule, dbtgSchedule) {
  let dbStartTime
  let dbEndTime
  let currentStartTime
  let currentEndTime

  if (source === COMPONENT_SHORT_HAND.CHARGE_BATTERY_FROM_GRID) {
    // dbStartTime and dbEndTime take from DTG schedule
    dbStartTime = dbtgSchedule?.startTime
    dbEndTime = dbtgSchedule?.endTime

    currentStartTime = cbfgSchedule?.startTime
    currentEndTime = cbfgSchedule?.endTime
  } else {
    // dbStartTime and dbEndTime take from CFG schedule
    dbStartTime = cbfgSchedule?.startTime
    dbEndTime = cbfgSchedule?.endTime

    currentStartTime = dbtgSchedule?.startTime
    currentEndTime = dbtgSchedule?.endTime
  }

  // convert startTime endTime in UTC date format
  dbStartTime = minutesToUTCDate(dbStartTime)
  dbEndTime = minutesToUTCDate(dbEndTime)
  currentStartTime = minutesToUTCDate(currentStartTime)
  currentEndTime = minutesToUTCDate(currentEndTime)

  // prepare time difference in minutes between CFG/DTG schedule
  const curStartToDbStartDiff = Math.abs(currentStartTime.diff(dbStartTime, 'minutes'))
  const curStartToDbEndDiff = Math.abs(currentStartTime.diff(dbEndTime, 'minutes'))
  const curEndToDbStartDiff = Math.abs(currentEndTime.diff(dbStartTime, 'minutes'))
  const curEndToDbEndDiff = Math.abs(currentEndTime.diff(dbEndTime, 'minutes'))

  // check CFG/DTG schedule should have atleast 5 min duration between each other
  if (
    curStartToDbStartDiff > 4 &&
    curStartToDbStartDiff < 1436 &&
    curStartToDbEndDiff > 4 &&
    curStartToDbEndDiff < 1436 &&
    curEndToDbStartDiff > 4 &&
    curEndToDbStartDiff < 1436 &&
    curEndToDbEndDiff > 4 &&
    curEndToDbEndDiff < 1436
  ) {
    return false
  }

  return true
}

// Discharge Battery to Grid :: Get Time Error // TODO : need to check can we create this method as common method
export function getTimeErrorDbtg(a, b) {
  /**
   * Avoid schedule validation for NEM3SelfConsExpOnly site
   * Optimise Schedule will be provided from API
   * In Export only, CFG will be hidden, so no need to validate overlap schedule as well
   */
  const details = getBatteryProfileDetails()
  if (isNEM3SelfConsExpOnlySite(details)) return null

  const error = getTimeError(a, b)
  if (error) return error

  if (isSiteGridAgnostic() && !chargeFromGridScheduleEnabled()) return null

  const {chargeFromGrid, chargeBeginTime, chargeEndTime} = getBatteryProfileDetails()
  if (showChargeFromGrid() && chargeFromGrid) {
    const dbtgSchedule = {startTime: stringToMinutes(a?.formatted12), endTime: stringToMinutes(b?.formatted12)}
    const cbfgSchedule = {startTime: chargeBeginTime, endTime: chargeEndTime}
    if (
      isCFGAndDTGScheduleOverlapping(COMPONENT_SHORT_HAND.DISCHARGE_BATTERY_TO_GRID, cbfgSchedule, dbtgSchedule) ||
      isCFGAndDTGScheduleDurationConflict(COMPONENT_SHORT_HAND.DISCHARGE_BATTERY_TO_GRID, cbfgSchedule, dbtgSchedule)
    ) {
      return i18next.t('bp409')
    }
  }
  return null
}

// Discharge Battery to Grid :: Show info icon
export function showInfoIcon(details) {
  return (
    getBatteryProfileDetails()?.profile === PROFILE_TYPE.SELF_CONSUMPTION && !isAnyRequestPendingForProfile(details)
  )
}

// Discharge Battery to Grid :: Get DTG calibration progress details
function getBatteryCalibrationProgressDetails() {
  const desc = i18next.t('bp408')
  return (
    <div className="cp_alert-popup__wrapper">
      <Icon src="alert" />
      <div className="cp_alert-popup__wrapper-content">{desc}</div>
    </div>
  )
}

// Discharge Battery to Grid :: Calibration Alert popup
export function getBatteryCalibrationAlertDetails(closeThisDialog) {
  const cpInfoDescription = getBatteryCalibrationProgressDetails()
  const obj = {
    className: 'cp_alert-popup',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content: cpInfoDescription,
    buttons: null,
  }

  obj.buttons = [
    {
      value: i18next.t('bp46'),
      action: closeThisDialog,
      disabled: false,
      className: 'bp__ab--mobile-view-hr',
    },
  ]

  return obj
}

// Discharge Battery to Grid :: Restrict user based on user role
export function restrictUserToPerformDTGAction({
  details,
  isCbfgLoading,
  loading,
  showDialog,
  closeThisDialog,
  isBatteryShutdownLoading,
  showToastMsg,
}) {
  // lock DTG if battery calibration is in progress
  if (isSettingsLocked()) {
    showDialog && showDialog(LockPopUp(closeThisDialog))
    return true
  }

  // lock DTG if profile request pending
  if (
    isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
  ) {
    showToastMsg &&
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp416'),
        autoClose: 3000,
      })
    return true
  }

  // lock DTG if request pending from discharge to grid
  if (loading || isAnyRequestPendingForDischargeBatteryToGrid(details)) return true

  // lock DTG if request pending from charge from grid / battery shutdown
  if (
    (showChargeFromGrid() && (isAnyRequestPendingForChargeBatteryFromGrid(details) || isCbfgLoading)) ||
    (isIQGWScheduleSupportedSite() && (isBatteryShutdownLoading || isAnyRequestPendingForBatteryShutdown(details)))
  ) {
    showToastMsg &&
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp407'),
        autoClose: 3000,
      })
    return true
  }

  // lock DTG if dtg is locked from db
  if (isDischargeToGridLocked(details)) {
    if (
      showToastMsg &&
      (details?.profile === PROFILE_TYPE.COST_SAVINGS || details?.profile === PROFILE_TYPE.BACKUP_ONLY)
    ) {
      const toastMsg = details?.profile === PROFILE_TYPE.COST_SAVINGS ? i18next.t('bp411') : i18next.t('bp406')
      showToastMsg &&
        showToast({
          type: TOAST_TYPE.INFO,
          message: toastMsg,
          autoClose: 3000,
        })
    }
    return true
  }

  return false
}

// Discharge Battery to Grid :: Get Discharge Start Time
export function getDischargeStartTime(details) {
  if (isDischargeStartTimeRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].dtgControl?.startTime
  }

  if (isDischargeStartTimeRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].dtgControl?.startTime
  }

  return !_.isNil(details?.dtgControl?.startTime)
    ? details?.dtgControl?.startTime
    : getBatteryProfileDetails().dtgControl?.startTime
}

// Discharge Battery to Grid :: Get Discharge End Time
export function getDischargeEndTime(details) {
  if (isDischargeEndTimeRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].dtgControl?.endTime
  }

  if (isDischargeEndTimeRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].dtgControl?.endTime
  }

  return !_.isNil(details?.dtgControl?.endTime)
    ? details?.dtgControl?.endTime
    : getBatteryProfileDetails().dtgControl?.endTime
}

// Discharge Battery to Grid :: Get Discharge Schedule Supported
export function getDischargeScheduleSupported(details) {
  if (isDischargeScheduleSupportedRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].dtgControl?.scheduleSupported
  }

  if (isDischargeScheduleSupportedRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].dtgControl?.scheduleSupported
  }

  return !_.isNil(details?.dtgControl?.scheduleSupported)
    ? details?.dtgControl?.scheduleSupported
    : getBatteryProfileDetails().dtgControl?.scheduleSupported
}

// Discharge Battery to Grid :: Get Discharge Locked
export function isDischargeToGridLocked(details) {
  if (isDischargeLockedRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && Boolean(details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].dtgControl?.locked)
  }

  if (isDischargeLockedRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && Boolean(details[PENDING_REQ_KEY.REQUESTED_CONFIG].dtgControl?.locked)
  }

  return !_.isNil(details?.dtgControl?.locked)
    ? details?.dtgControl?.locked
    : Boolean(getBatteryProfileDetails().dtgControl?.locked)
}

// Discharge Battery to Grid :: Get Disclaimer Popup
export function getDisclaimerPopup({checked, onChangeDisclaimerCheckbox}) {
  return (
    <div className="dbtg__popup__wrapper">
      <div className="e_dialog__content">{i18next.t('bp398')}</div>
      <div className="e_dialog__content bp__radio-checkbox__button">
        <input
          type="checkbox"
          role="checkbox"
          id="itc-checkbox"
          defaultChecked={checked}
          onChange={() => onChangeDisclaimerCheckbox(!checked)}
        />
        <div className="bp__radio-checkbox__description">
          <label htmlFor="itc-checkbox" aria-labelledby="itc-checkbox">
            {i18next.t('bp399')}
          </label>
        </div>
      </div>
    </div>
  )
}

// Discharge Battery to Grid :: Calibration Alert popup
export function getNEM3ExpOnlyTariffAlertDetails(closeThisDialog) {
  function getNEM3ExpOnlyTariffAlertContent() {
    const updateElectricityRate = () => {
      navigateToElectricityRate()
      popupFromIframe({displayFooter: false, dialogType: DIALOG_TYPE.CENTER})
    }

    return (
      <div className="nem3_alert-popup__wrapper">
        <div className="nem3_alert-popup__wrapper-header-title">{i18next.t('bp463')}</div>
        <div className="nem3_alert-popup__wrapper-content">
          <Icon src="error" />
          <div className="nem3_alert-popup__wrapper-content-description">{i18next.t('bp464')}</div>
        </div>
        <div className="nem3_alert-popup__wrapper-button">
          <button type="button" className="secondary-btn" onClick={() => closeThisDialog()}>
            {i18next.t('bp99')}
          </button>
          <button type="button" className="primary-btn" onClick={updateElectricityRate}>
            {i18next.t('bp465')}
          </button>
        </div>
      </div>
    )
  }

  const alertContent = getNEM3ExpOnlyTariffAlertContent()
  return {
    className: 'nem3_alert-dialog',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content: alertContent,
    buttons: null,
  }
}

export function isNEM3SelfConsExpOnlySite(details) {
  return isNEM3ExpOnlySite() && getSystemProfile(details) === PROFILE_TYPE.SELF_CONSUMPTION && getGridConnectionType() === 1
}

export function noScheduleForNEM3SelfConsExpOnlySite(details, checkReqData = false) {
  if (!isNEM3SelfConsExpOnlySite(details)) return false
  let {startTime, endTime} = details.dtgControl

  if (checkReqData) {
    startTime = getDischargeStartTime(details)
    endTime = getDischargeEndTime(details)
  }

  return (startTime === null && endTime === null) || (startTime === 0 && endTime === 0)
}
