import Icon from "../../../../utils/icon";
import i18next from "i18next";
import { DIALOG_TYPE } from "../../../../utils/constants";
import "./style.scss";

export default function getLockDetails(cb) {
  const content = (
    <div className="lock__popup__wrapper">
      <div className="lock__wrapper__icon">
        <Icon src="alert" />
      </div>
      <div className="e_dialog__content">
        <p>{i18next.t("bp495")}</p>
        <p>{i18next.t("bp496")}</p>
      </div>
    </div>
  );

  const obj = {
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: "",
    content: content,
  };

  obj.buttons = [
    {
      value: i18next.t("bp46"),
      action: cb,
      disabled: false,
      className: "bp__ab--locked",
    },
  ];

  return obj;
}
