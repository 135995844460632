import React from 'react'

const Success = () => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45.0802 23.9201C44.7054 23.5476 44.1985 23.3386 43.6701 23.3386C43.1418 23.3386 42.6349 23.5476 42.2602 23.9201L28.5001 37.6801L22.7401 31.9201C22.5608 31.7107 22.3402 31.5407 22.0921 31.4207C21.8439 31.3006 21.5737 31.2332 21.2982 31.2225C21.0228 31.2119 20.7481 31.2583 20.4915 31.3588C20.2348 31.4594 20.0018 31.6119 19.8068 31.8068C19.6119 32.0017 19.4594 32.2348 19.3589 32.4915C19.2583 32.7481 19.2119 33.0228 19.2226 33.2982C19.2332 33.5737 19.3006 33.8439 19.4207 34.092C19.5407 34.3402 19.7108 34.5608 19.9201 34.7401L27.0802 41.9201C27.2661 42.1076 27.4873 42.2564 27.731 42.3579C27.9747 42.4594 28.2361 42.5117 28.5001 42.5117C28.7642 42.5117 29.0256 42.4594 29.2693 42.3579C29.513 42.2564 29.7342 42.1076 29.9201 41.9201L45.0802 26.7401C45.4527 26.3654 45.6617 25.8585 45.6617 25.3301C45.6617 24.8018 45.4527 24.2948 45.0802 23.9201Z" fill="#14C415"/>
    <path d="M32.5 4.5C26.9621 4.5 21.5486 6.14219 16.944 9.21887C12.3395 12.2956 8.75061 16.6685 6.63136 21.7849C4.5121 26.9012 3.95765 32.5311 5.03803 37.9625C6.11842 43.394 8.78513 48.3831 12.701 52.299C16.6169 56.2149 21.606 58.8816 27.0375 59.962C32.469 61.0424 38.0988 60.4879 43.2152 58.3687C48.3315 56.2494 52.7045 52.6606 55.7811 48.056C58.8578 43.4514 60.5 38.0379 60.5 32.5C60.5 25.0739 57.55 17.952 52.299 12.701C47.048 7.44997 39.9261 4.5 32.5 4.5ZM32.5 56.5C27.7533 56.5 23.1131 55.0924 19.1663 52.4553C15.2196 49.8181 12.1434 46.0698 10.3269 41.6844C8.51041 37.299 8.03515 32.4734 8.96119 27.8178C9.88724 23.1623 12.173 18.8859 15.5294 15.5294C18.8859 12.173 23.1623 9.88723 27.8178 8.96118C32.4734 8.03514 37.299 8.5104 41.6844 10.3269C46.0698 12.1434 49.8181 15.2195 52.4553 19.1663C55.0924 23.1131 56.5 27.7533 56.5 32.5C56.5 38.8652 53.9715 44.9697 49.4706 49.4706C44.9697 53.9715 38.8652 56.5 32.5 56.5Z" fill="#14C415"/>
  </svg>
)

export default Success
