/* eslint-disable fp/no-mutation */

import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {setPdValue} from 'lib/component/common/organisms/ProfileDetails/pdSlice'
import {batteryProfileSelector} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {PAGE_NAME, PENDING_REQ_KEY, SOURCE_TYPE} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {showHeader} from 'lib/utils/pageDetails'
import {
  hasSystemCard,
  hasEncharge,
  isDTSupportedSite,
  customScheduleEnabled,
  isHemsSite,
  isEvseSite,
  isEvChargingModesSupported,
} from 'lib/utils/siteDetails'
import {setLocale, isWeb, isEnhoWeb, isSource} from '../../../../utils/utility'
import {Dialog} from '../../atoms/Dialog'
import ShowErrorDetails from '../../atoms/Error/showErrorDetails'
import EvTopUpSchedule from '../../atoms/EvTopUpSchedule'
import Header from '../../atoms/Header'
import Loader from '../../atoms/Loader'
import SystemProfile from '../../atoms/SystemProfile'
import {Toast} from '../../atoms/Toast'
import {setBrValue} from '../../molecules/BatteryReserve/brSlice'
import {StormGuardDetails} from '../StormGuardDetails'
import {updateBatterySliceValues} from '../BatteryDetails/bdCore'
import {selectTutorialDetails} from '../hspCommon'
import {closeWebView} from '../bpCommon'
import {isBatteryBackupPercentageRequestPending} from '../reqPending'
import {init} from './pdCore'
import 'react-toastify/dist/ReactToastify.min.css'
import './styles.scss'

/* *********************** *********************** *********************** *
 *  Profile Details
 * *********************** *********************** *********************** */

const ProfileDetails = () => {
  const loader = useSelector(state => state.components.loader)
  const dialogDetails = useSelector(state => state.components.dialogDetails)
  const batteryProfile = useSelector(batteryProfileSelector)
  const {details} = batteryProfile
  const batteryReserve = useSelector(state => state.components.batteryReserve)
  const ProfileDetail = useSelector((state => state.components.profileDetails))
  
  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const setBrSlice = obj => dispatch(setBrValue({...obj}))
  const setPdSlice = obj => dispatch(setPdValue({...obj}))

  const reqProfile =
    batteryProfile.details?.requestedConfigMqtt?.profile ||
    batteryProfile.details?.requestedConfig?.profile ||
    batteryProfile.details?.profile

  // useEffect on mount
  useEffect(() => {
    setLocale()
    init({dispatch})
    document.title = i18next.t('bp192')
  }, [])

  useEffect(() => {
    if(ProfileDetail.fetchProfileDetails) {
      init({dispatch})
      setPdSlice({ fetchProfileDetails: false })
    }
  }, [ProfileDetail.fetchProfileDetails])

  // useEffect on batteryProfile loading
  useEffect(() => {
    setPdSlice({openCard: reqProfile})

    updateBatterySliceValues({
      source: 'reserve',
      dispatch,
      profile: batteryProfile.details.profile,
      data: batteryProfile.details,
    })
    setBrSlice({
      loading: isBatteryBackupPercentageRequestPending(batteryProfile.details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT),
    })
  }, [batteryProfile.loading])

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  // System Profile Tutorial :: onClick header action
  const onClickHeaderAction = () => {
    if (isWeb()) closeWebView()
    else
      selectTutorialDetails({
        showDialog,
        closeThisDialog,
        details,
        setBrSlice,
        batteryReserve,
        setPdSlice,
        screen: 1,
        tutorialObj: {firstVal: 1, secondVal: 2},
      })
  }

  const setActionTitle = () => {
    if (hasEncharge() || isDTSupportedSite()) {
      if (isEnhoWeb()) return <Icon src="close" />
      // if (!batteryProfile.invalidAccess && isSource(SOURCE_TYPE.ITK)) return <Icon src="tutorialHelp" />
      return null
    }
    return null
  }

  // Show add-on Ev schedules
  const showEvSchedules = () =>
    ((isHemsSite() && customScheduleEnabled()) || (isEvseSite() && isSource(SOURCE_TYPE.ENHO))) && hasSystemCard()

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    // Profile Details
    <div id="bp-page-profile-details" className="bp__page bp__page--open">
      {showHeader() && (
        <Header
          id={PAGE_NAME.PROFILE}
          title={i18next.t('bp192')}
          actionTitle={setActionTitle()}
          onClickAction={onClickHeaderAction}
        />
      )}

      {!(batteryProfile.apiError || batteryProfile.invalidAccess) && (
        <div className="bp__page--wrapper">
          <StormGuardDetails />
          <SystemProfile profile={reqProfile} />
          {showEvSchedules() && !isEvChargingModesSupported() && <EvTopUpSchedule />}
        </div>
      )}

      {batteryProfile.apiError ? (
        <ShowErrorDetails />
      ) : (
        batteryProfile.invalidAccess && <ShowErrorDetails text={i18next.t('bp194')} subText={i18next.t('bp195')} />
      )}

      {!(batteryProfile.apiError || batteryProfile.invalidAccess) && <Toast />}

      <Loader {...loader} />
      <Dialog
        id={dialogDetails.id}
        className={`e_dialog__profile ${dialogDetails.className}`}
        ariaLabel={dialogDetails.ariaLabel}
        type={dialogDetails.type}
        showCloseIcon={dialogDetails.showCloseIcon}
        showDialog={dialogDetails.showDialog}
        title={dialogDetails.title}
        content={dialogDetails.content}
        buttons={dialogDetails.buttons}
        onClickCloseIcon={dialogDetails.onClickCloseIcon}
        closeOnClickOverlay={dialogDetails.closeOnClickOverlay}
      />
    </div>
  )
}

ProfileDetails.propTypes = {}

ProfileDetails.defaultProps = {}

export default ProfileDetails
