import React from 'react'

const ErrorV2 = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.75 32C58.75 46.7736 46.7736 58.75 32 58.75C17.2264 58.75 5.25 46.7736 5.25 32C5.25 17.2264 17.2264 5.25 32 5.25C46.7736 5.25 58.75 17.2264 58.75 32Z" stroke="#DE3C41" strokeWidth="2.5"/>
    <path d="M34.5039 14.3359H30.6758L31.0087 34.5046H34.1354L34.5039 14.3359Z" fill="#DE3C41"/>
    <path d="M34.4961 42.3359H30.668V46.1641H34.4961V42.3359Z" fill="#DE3C41"/>
  </svg>
)

export default ErrorV2
