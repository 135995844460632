/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import _ from 'lodash'
import {getBatteryProfileDetails} from 'lib/store'
import {
  ACTION_TYPE,
  COUNTRY_CODE,
  PENDING_REQ_KEY,
  PROFILE_STATUS,
  TOGGLE_SWITCH_STATUS,
  DIALOG_TYPE,
  COMPONENT_SHORT_HAND,
  TOAST_TYPE,
  PROFILE_TYPE,
  STORM_GUARD_STATUS,
} from 'lib/utils/constants'
import {
  IQGWScheduleSupportWithSavingsMode,
  dischargeToGridEnable,
  getSiteCountryCode,
  isSettingsLocked,
  isIQGWScheduleSupportedSite,
  isSiteGridAgnostic,
  isSiteGridTied,
  showDischargeToGrid,
} from 'lib/utils/siteDetails'
import {FEATURE_KEYS, isFeatureSupported} from 'lib/utils/featureKeys'
import {contactSupport, popupFromIframe} from 'lib/common'
import {stringToMinutes} from 'lib/utils/timeDetails'
import {getTimeError} from 'lib/utils/utility'
import {
  isAnyRequestPendingForChargeBatteryFromGrid,
  isAnyRequestPendingForDischargeBatteryToGrid,
  isChargeBeginTimeRequestPending,
  isChargeEndTimeRequestPending,
  isChargeFromGridRequestPending,
  isChargeFromGridScheduleSupportedRequestPending,
  isChargeFromGridLockedRequestPending,
  isProfileRequestPending,
  isRequestPendingForChargeBatteryFromGrid,
  isScheduleEnabledRequestPending,
  isAnyRequestPendingForProfile,
  isAnyRequestPendingForBatteryShutdown,
} from '../../organisms/reqPending'
import Icon from '../../../../utils/icon'
import {
  isCFGAndDTGScheduleDurationConflict,
  isCFGAndDTGScheduleOverlapping,
  getBatteryCalibrationAlertDetails,
  getSystemProfile,
} from '../DischargeBatteryToGrid/dbtgCore'
import {showToast} from '../../atoms/Toast'
import LockPopUp from '../LockPopUp'

// Charge Battery from Grid :: Card Details
export function getCardDetails(details) {
  const isAnyReqPending = isAnyRequestPendingForChargeBatteryFromGrid(details)
  const isMqttReqPending = isRequestPendingForChargeBatteryFromGrid(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)
  const isReqPending = isRequestPendingForChargeBatteryFromGrid(details, PENDING_REQ_KEY.REQUESTED_CONFIG)

  if (details && !isAnyReqPending) {
    return {
      type: PROFILE_STATUS.ACTIVE,
      typeText: 'Active',
      buttonLabel: i18next.t('bp210'),
      buttonActionType: ACTION_TYPE.SAVE,
    }
  }

  if (isMqttReqPending) {
    return {
      type: PROFILE_STATUS.PROCESSING,
      typeText: null,
      buttonLabel: i18next.t('bp210'),
      buttonActionType: ACTION_TYPE.SAVE,
    }
  }

  if (isReqPending) {
    return {
      type: PROFILE_STATUS.PENDING,
      typeText: i18next.t('bp100'),
      buttonLabel: i18next.t('bp49'),
      buttonActionType: ACTION_TYPE.CANCEL,
    }
  }

  return {
    buttonLabel: i18next.t('bp210'),
    buttonActionType: ACTION_TYPE.SAVE,
  }
}

// Charge Battery from Grid :: Get Toggle Status
export function getCbfgToggleStatus(details, loading) {
  const onSubType = getChargeFromGrid(details)

  if (loading) {
    return TOGGLE_SWITCH_STATUS.LOADING
  }

  if (onSubType) {
    return TOGGLE_SWITCH_STATUS.ON
  }

  return TOGGLE_SWITCH_STATUS.OFF
}

// Charge Battery from Grid :: Get Charge from Grid status
export function getChargeFromGrid(details) {
  if (isChargeFromGridRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].chargeFromGrid
  }

  if (isChargeFromGridRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].chargeFromGrid
  }

  return details?.chargeFromGrid || false
}

// Charge Battery from Grid :: Get Charge Begin Time
export function getChargeBeginTime(details) {
  if (isChargeBeginTimeRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].chargeBeginTime
  }

  if (isChargeBeginTimeRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].chargeBeginTime
  }

  return !_.isNil(details?.chargeBeginTime) ? details?.chargeBeginTime : getBatteryProfileDetails().chargeBeginTime
}

// Charge Battery from Grid :: Get Charge End Time
export function getChargeEndTime(details) {
  if (isChargeEndTimeRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].chargeEndTime
  }

  if (isChargeEndTimeRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].chargeEndTime
  }

  return !_.isNil(details?.chargeEndTime) ? details?.chargeEndTime : getBatteryProfileDetails().chargeEndTime
}

// Charge Battery from Grid :: Get Schedule Enabled
export function getScheduleEnabled(details) {
  if (isScheduleEnabledRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].chargeFromGridScheduleEnabled
  }

  if (isScheduleEnabledRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].chargeFromGridScheduleEnabled
  }

  return details?.chargeFromGridScheduleEnabled || false
}

// Charge Battery from Grid :: Get Description
export function getDescription(status, details) {
  let text = ''
  if (isIQGWScheduleSupportedSite()) {
    const profile = getSystemProfile(details)
    text = i18next.t('bp62')
    if (status === TOGGLE_SWITCH_STATUS.ON) {
      switch (profile) {
        case PROFILE_TYPE.COST_SAVINGS:
          text = i18next.t('bp438')
          break
        case PROFILE_TYPE.BACKUP_ONLY:
          text = i18next.t('bp429')
          break
        case PROFILE_TYPE.SELF_CONSUMPTION:
          text = isSiteGridAgnostic() ? i18next.t('bp427') : i18next.t('bp426')
          break
        default:
          break
      }
    }
  } else if (!showNewCFG() && !isSiteGridTied()) {
    if (status === TOGGLE_SWITCH_STATUS.ON) {
      text = i18next.t('bp239')

      if (isSiteGridAgnostic()) {
        text = i18next.t('bp239')
      }
    } else if (status === TOGGLE_SWITCH_STATUS.OFF) {
      text = i18next.t('bp62')

      if (isSiteGridAgnostic()) {
        text = i18next.t('bp62')
      }
    }
  } else if (!showNewCFG() && isSiteGridTied()) {
    if (status === TOGGLE_SWITCH_STATUS.ON) {
      text = i18next.t('bp60')
    } else if (status === TOGGLE_SWITCH_STATUS.OFF) {
      text = i18next.t('bp62')
    }
  } else if (showNewCFG()) {
    if (status === TOGGLE_SWITCH_STATUS.ON) {
      text = i18next.t('bp60')

      if (isSiteGridAgnostic()) {
        text = i18next.t('bp61')
      }
    } else if (status === TOGGLE_SWITCH_STATUS.OFF) {
      text = i18next.t('bp62')

      if (isSiteGridAgnostic()) {
        text = i18next.t('bp63')
      }
    }
  }
  return text
}

// Charge Battery from Grid :: Show info icon
export function showInfoIcon(details) {
  if (!isIQGWScheduleSupportedSite()) return true

  const profile = details?.profile
  return (
    (profile === PROFILE_TYPE.SELF_CONSUMPTION || profile === PROFILE_TYPE.BACKUP_ONLY) &&
    !isAnyRequestPendingForProfile(details)
  )
}

// Charge Battery from Grid :: Get Info Description
export function getInfoDescription() {
  let descEnabled = ''
  let descDisabled = ''
  let descNote

  if (isIQGWScheduleSupportedSite()) {
    const profile = getBatteryProfileDetails()?.profile
    descDisabled = i18next.t('bp62')
    switch (profile) {
      case PROFILE_TYPE.COST_SAVINGS:
        descEnabled = i18next.t('bp438')
        break
      case PROFILE_TYPE.BACKUP_ONLY:
        descEnabled = i18next.t('bp429')
        break
      case PROFILE_TYPE.SELF_CONSUMPTION:
        descEnabled = isSiteGridAgnostic() ? i18next.t('bp427') : i18next.t('bp426')
        break
      default:
        break
    }
  } else if (!showNewCFG() && !isSiteGridTied()) {
    descEnabled = i18next.t('bp60')
    descDisabled = i18next.t('bp62')

    if (isSiteGridAgnostic()) {
      descEnabled = i18next.t('bp240')
      descDisabled = i18next.t('bp241')
    }
  } else if (!showNewCFG() && isSiteGridTied()) {
    descEnabled = i18next.t('bp60')
    descDisabled = i18next.t('bp62')
  } else if (showNewCFG() || isSiteGridTied) {
    descEnabled = i18next.t('bp60')
    descDisabled = i18next.t('bp62')
    descNote = i18next.t('bp97')

    if (isSiteGridAgnostic()) {
      descEnabled = i18next.t('bp61')
      descDisabled = i18next.t('bp63')
    }
  }

  return (
    <div className="cbfg__popup__wrapper">
      <div className="e_dialog__content">
        <span className="bp__status-text--enabled">{i18next.t('bp7')}</span>: {descEnabled}
      </div>
      <div className="e_dialog__content">
        <span className="bp__status-text--disabled">{i18next.t('bp8')}</span>: {descDisabled}
      </div>
      {Boolean(descNote) && (
        <div className="e_dialog__content" style={{color: '#7B8793'}}>
          <span className="bp__status-text--disabled" style={{color: '#7B8793'}}>
            {i18next.t('bp98')}
          </span>
          : {descNote}
        </div>
      )}
    </div>
  )
}

// Charge Battery from Grid :: Get ITC Description
export function getItcDescription({checked, onChangeItcDisclaimerCheckbox}) {
  let desc = i18next.t('bp267')
  let checkboxText = i18next.t('bp268')

  if (getSiteCountryCode() === COUNTRY_CODE.US) {
    desc = i18next.t('bp64')
    checkboxText = i18next.t('bp65')
  }

  return (
    <div className="cbfg__popup__wrapper">
      <div className="e_dialog__content">{desc}</div>
      <div className="e_dialog__content bp__radio-checkbox__button">
        <input
          type="checkbox"
          role="checkbox"
          id="itc-checkbox"
          defaultChecked={checked}
          onChange={() => onChangeItcDisclaimerCheckbox(!checked)}
        />
        <div className="bp__radio-checkbox__description">
          <label htmlFor="itc-checkbox" aria-labelledby="itc-checkbox">
            {checkboxText}
          </label>
        </div>
      </div>
    </div>
  )
}

// Charge Battery from Grid :: Get Time Error
export function getTimeErrorCbfg(a, b, chargeFromGridScheduleEnabled) {
  const error = getTimeError(a, b)
  if (error) return error

  if (isSiteGridAgnostic() && !chargeFromGridScheduleEnabled) return null

  if (showDischargeToGrid() && dischargeToGridEnable()) {
    const {
      dtgControl: {startTime, endTime},
    } = getBatteryProfileDetails()

    const cbfgSchedule = {startTime: stringToMinutes(a?.formatted12), endTime: stringToMinutes(b?.formatted12)}
    const dbtgSchedule = {startTime, endTime}
    if (
      isCFGAndDTGScheduleOverlapping(COMPONENT_SHORT_HAND.CHARGE_BATTERY_FROM_GRID, cbfgSchedule, dbtgSchedule) ||
      isCFGAndDTGScheduleDurationConflict(COMPONENT_SHORT_HAND.CHARGE_BATTERY_FROM_GRID, cbfgSchedule, dbtgSchedule)
    ) {
      return i18next.t('bp409')
    }
  }
  return null
}

// Checks for new CFG UI
export function showNewCFG() {
  return isFeatureSupported(FEATURE_KEYS.CFG_NEW_LOGIC)
}

// Charge Battery from Grid :: Restrict CFG for eSW < 8.2.82 for UK
export function getRestrictCFGDescription() {
  const navigateToSupport = () => {
    contactSupport()
    popupFromIframe({displayFooter: false, dialogType: DIALOG_TYPE.CENTER})
  }
  return (
    <div className="cbfg__popup__wrapper">
      <Icon src="infoAlert" />
      <div className="e_dialog__content">
        {i18next.t('bp337')}{' '}
        <span
          className="contact_support_link"
          onKeyDown={() => {}}
          role="button"
          tabIndex="0"
          onClick={() => navigateToSupport()}>
          {i18next.t('bp342')}
        </span>
      </div>
    </div>
  )
}

// Charge Battery from Grid :: Get Charge Locked
export function isChargeFromGridLocked(details) {
  if (isChargeFromGridLockedRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && Boolean(details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].cfgControl?.locked)
  }

  if (isChargeFromGridLockedRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && Boolean(details[PENDING_REQ_KEY.REQUESTED_CONFIG].cfgControl?.locked)
  }

  return !_.isNil(details?.cfgControl?.locked)
    ? details?.cfgControl?.locked
    : Boolean(getBatteryProfileDetails().cfgControl?.locked)
}

// Charge Battery from Grid :: Restrict user to perform CFG action
export function restrictUserToPerformCFGAction({
  details,
  isDbtgLoading,
  loading,
  showDialog,
  closeThisDialog,
  isBatteryShutdownLoading,
  showToastMsg,
}) {

  // lock CFG if battery calibration is in progress
  if (isSettingsLocked()) {
    showDialog && showDialog(LockPopUp(closeThisDialog))
    return true
  }
// skip CFG validation if site not running on 24 hr JSON
  if (!isIQGWScheduleSupportedSite()) return false


  

  // lock CFG if profile request pending
  if (
    isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
  ) {
    showToastMsg &&
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp416'),
        autoClose: 3000,
      })
    return true
  }

  // lock CFG if request pending from charge from grid
  if (loading || isAnyRequestPendingForChargeBatteryFromGrid(details)) return true

  // lock CFG if request pending from discharge battery to grid
  if (
    (showDischargeToGrid() && (isAnyRequestPendingForDischargeBatteryToGrid(details) || isDbtgLoading)) ||
    (isIQGWScheduleSupportedSite() && (isBatteryShutdownLoading || isAnyRequestPendingForBatteryShutdown(details)))
  ) {
    showToastMsg &&
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp407'),
        autoClose: 3000,
      })
    return true
  }

  // lock CFG if cfg is locked from db
  if (isChargeFromGridLocked(details)) {
    const profileToastMsg = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp439') : i18next.t('bp425')
    if (
      showToastMsg &&
      (details?.profile === PROFILE_TYPE.COST_SAVINGS || details?.stormGuardState === STORM_GUARD_STATUS.ACTIVE)
    ) {
      const toastMsg = details?.stormGuardState === STORM_GUARD_STATUS.ACTIVE ? i18next.t('bp244') : profileToastMsg
      showToast({
        type: TOAST_TYPE.INFO,
        message: toastMsg,
        autoClose: 3000,
      })
    }
    return true
  }

  return false
}

// Charge Battery from Grid :: Get CFG Schedule Supported
export function getChargeFromGridScheduleSupported(details) {
  if (isChargeFromGridScheduleSupportedRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].cfgControl?.scheduleSupported
  }

  if (isChargeFromGridScheduleSupportedRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].cfgControl?.scheduleSupported
  }

  return !_.isNil(details?.cfgControl?.scheduleSupported)
    ? details?.cfgControl?.scheduleSupported
    : getBatteryProfileDetails().cfgControl?.scheduleSupported
}
