import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'
import {TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'

const initialState = {
  loading: false,
  openCard: null,
  inProgress: null,
  requestType: null,

  isAfterPeakLoading: false,
  operationModeSubType: TOGGLE_SWITCH_STATUS.OFF,
  refreshEvStatus: false,
  fetchProfileDetails: false,
}

export const profileDetailsSlice = createSlice({
  name: 'profileDetails',
  initialState,
  reducers: {
    setPdValue: (state, action) => {
      const {payload} = action
      const states = {...state}

      return updateSpecificKeyValue(payload, states)
    },
  },
})

// Action creators are generated for each case reducer function
export const {setPdValue} = profileDetailsSlice.actions
export default profileDetailsSlice.reducer
